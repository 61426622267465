import { Link } from "gatsby";
import * as React from "react";
import MarkdownView from "react-showdown";
import Image from "../components/image";
import Byline from "./byline";
import Layout from "../components/layout";
import { CONSTANTS } from "./constants";
import Seo from "./seo";
import { markdownSeoDescription } from "./utils";

const RenderPost = ({ data, preview }) => {
  return (
    <Layout altBg={true}>
      {!preview && (
        <Seo
          title={`Conversation: ${data.post.intervieweeName}`}
          description={markdownSeoDescription(data.post.interviewIntro)}
          image={data.post.intervieweeImage?.localFile.publicURL}
          meta={[
            {
              property: `og:url`,
              content: `${CONSTANTS.URL}/${data.post.slug}/`,
            },
          ]}
        />
      )}
      <div className="section post">
        <div className="row gtr-uniform aln-center">
          <div className="col-7 col-9-medium col-12-small post">
            <div className="heading medium-bottom-margin">
              <Link to="/conversations/">
                <h1>conversation</h1>
              </Link>
              <h2>{data.post.intervieweeName}</h2>
              {!preview && (
                <Byline
                  byline={data.post.author}
                  authors={data.authors}
                  published_at={data.post.published_at}
                />
              )}
            </div>
            <>
              {preview && (
                <span className="image fit">
                  <img
                    src={data.post.intervieweeImage?.url}
                    alt={data.post.intervieweeImage?.alternativeText}
                  />
                </span>
              )}

              {!preview && (
                <div className="image fit">
                  <Image image={data.post.intervieweeImage} />
                </div>
              )}
            </>
            <MarkdownView
              markdown={data.post.interviewIntro}
              className="conversation medium-top-margin"
            />
            <MarkdownView
              markdown={data.post.interviewContent}
              className="conversation medium-top-margin"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default RenderPost;
