import { graphql } from "gatsby";
import * as React from "react";
import RenderPost from "../components/renderPost";

export const postsQuery = graphql`
  query postData($postId: Int!, $authorIds: [Int]!) {
    post: strapiOkCarbonPosts(strapiId: { eq: $postId }) {
      slug
      strapiId
      title
      updated_at
      published_at(formatString: "MMMM DD, YYYY")
      otherPicksTitle
      mainPickTitle
      created_at
      interviewIntro
      interviewContent
      intervieweeName
      intervieweeImage {
        url
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 320, quality: 75)
          }
          extension
          publicURL
        }
        caption
        alternativeText
      }
      products {
        created_at
        discountedPrice
        slug
        published_at
        price
        name
        link
        linkText
        images {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            extension
            publicURL
          }
          caption
          alternativeText
        }
        updated_at
      }
      main_product_pick {
        created_at
        description
        link
        linkText
        images {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            extension
            publicURL
          }
          caption
          alternativeText
        }
        name
        price
        published_at
        slug
        updated_at
      }
      author {
        id
        firstname
        lastname
      }
    }
    authors: allStrapiAuthorPages(
      filter: { user: { id: { in: $authorIds } } }
    ) {
      edges {
        node {
          user {
            id
            firstname
            lastname
          }
          jobTitle
          longBio
          shortBio
          image {
            formats {
              thumbnail {
                url
              }
              small {
                url
              }
              medium {
                url
              }
              large {
                url
              }
            }
          }
        }
      }
    }
  }
`;

const Post = ({ pageContext, data }) => {
  return <RenderPost data={data} />;
};

export default Post;
